<template>
  <div class="services-page">
    <div class="navbar-canvas"></div>

    <b-container>
      <LineInfo title="Список поставляемых товаров и оказываемых услуг" />
    </b-container>

    <div class="serv">
      <img id="serv-rictangle" src="@/assets/img/rictangle.png" alt="" />

      <b-container class="services-blocks">
        <b-row>
          <b-col cols="12" md="6" class="block_left">
            <b-row align-v="center" class="block__header">
              <b-col cols="12" md="2">
                <div class="circle-logo-canvas">
                  <img class="circle-logo-canvas_img" :src="require('@/assets/svg/icon-services-box.svg')">  
                </div>
              </b-col>

              <b-col cols="12" md="10">
                <h2 class="title">Категории поставляемых товаров</h2>
                <!-- <p class="subtitle">
                  Только профессиональные решения для клиентов
                </p> -->
              </b-col>
            </b-row>

            <div v-swiper:mySwiper="swiperOption">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  v-for="(item_data, index) in carousel_data"
                  :key="index"
                >
                  <b-col cols="12" class="card-text">
                    <h3 class="card_title">{{ item_data.title }}</h3>
                    <p class="card_text" v-html="item_data.text"></p>
                  </b-col>
                </div>
              </div>

              <div class="swiper-pagination"></div>
            </div>
          </b-col>

          <b-col cols="12" md="6" class="block">
            <b-row align-v="center" class="block__header">
              <b-col cols="12" md="2">
                <div class="circle-logo-canvas">
                  <img class="circle-logo-canvas_img" :src="require('@/assets/svg/icon-services-conversation.svg')">
                </div>
              </b-col>

              <b-col cols="12" md="10">
                <h2 class="title">Виды работ и услуг</h2>
                <!-- <p class="subtitle">
                  Только профессиональные решения для клиентов
                </p> -->
              </b-col>
            </b-row>

            <div>
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  v-for="(item_data, index) in carousel_services"
                  :key="index"
                >
                  <div class="col-12 card-text">
                    <h3 class="card_title">{{ item_data.title }}</h3>
                    <p class="card_text" v-html="item_data.text"></p>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>

        <!-- <div class="bottom-block row">
          <b-col cols="12">
            <h4 class="help-text text-center">
              Не можете найти нужную услугу или товар?
            </h4>
          </b-col>
          <b-col cols="12">
            <b-row align-h="center">
              <button id="button_outline">Посмотреть все</button>
            </b-row>
          </b-col>
        </div> -->
      </b-container>
    </div>

    <ContactForm />

    <MoreInfo />

    <!-- <News /> -->
  </div>
</template>

<script>
import News from "@/components/News.vue";
import MoreInfo from "@/components/MoreInfo.vue";
import ContactForm from "@/components/ContactForm.vue";
import LineInfo from "@/components/LineInfo.vue";

export default {
  name: "Services",
  components: {
    News,
    MoreInfo,
    ContactForm,
    LineInfo,
  },
  data() {
    return {
      carousel_data: [
        {
          title: "Общехозяйственное направление",
          text: `
          1. Сувенирная, брендированная продукция (дилер, опыт поставки 4 года).<br />
          2. Медицинские товары (рециркуляторы, расходные материалы, медтехника (опыт поставки 10 лет).<br />
          3. Товары хозяйственно-бытового назначения (опыт поставки 6 лет).<br />
          4. Бытовая техника (дилер, опыт поставки 6 лет).<br />
          5. Межкомнатные двери премиум-класса, интерьерные двери.<br />
          6. Мебель для офиса и гостиничные принадлежности (дилер, опыт поставки 2 года).<br />
          7. Окна и двери из ПВХ и алюминиевых профилей, светопрозрачные конструкции и витражи (дилер, опыт поставки 7 лет).`,
        },
        {
          title: "Электротехника и IT-оборудование",
          text: `
          1. Электротехническая продукция (дилер, опыт поставки 10 лет).<br />
          2. Оборудование систем пожарной сигнализации и оповещения людей о пожаре (дилер, опыт поставки 7 лет).<br />
          3. Воздухоочистители и бризеры (дилер, опыт поставки 2 года).<br />
          4. Пассивное оборудование и комплектующие ВОЛС, СКС, ЛВС (опыт поставки 10 лет).<br />
          5. Huawei.<br />
          6. Cisco.<br />
          7. IP-телефония Avaya, Eltex (дилер, опыт поставки 1 год).<br />
          8. Источники бесперебойного питания офисного и промышленного направления.`,
        },
        {
          title: "Производственное направление",
          text: `
          1. Шкафы и системы автоматизации (производитель, опыт поставки 2 года).<br />
          2. Нестандартизированное оборудование из титана и нержавеющей стали: реакторы, мачты, металлоконструкции; деревянные конструкции, полимерная продукция, вентиляция (дилер, опыт поставки 1 год).
          `,
        },
      ],
      carousel_services: [
        {
          title: "Профессиональная логистика",
          text: `
          1. Противопожарные двери.<br />
          2. Монтаж светопрозрачных конструкций, витражей, окон.<br />
          3. Установка видеонаблюдения.<br />
          5. Прокладка ВОЛС.<br />
          6. АСКУЭ.<br />
          7. Узловая сборка поставляемого оборудования.<br />
          8. Установка и настройка IP-телефонии.`,
        },
      ],
      swiperOption: {
        mousewheel: {
          forceToAxis: true
        },
        autoplay: {
          delay: 3000
        },
        slidesPerView: 1,
        spaceBetween: 15,
        // Responsive breakpoints
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 15,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 1,
            spaceBetween: 15,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 1,
            spaceBetween: 15,
          },
        },

        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
      },
    };
  },
};
</script>

<style lang="scss">
/* absolute */
.news-canvas {
  position: relative;
}

.services-page {
  position: relative;
  overflow: hidden;
}
.header-services {
  min-height: 60vh;
  padding-top: 10vh;
  padding-bottom: 10vh;
  position: relative;
  z-index: 2;
}

/* blocks */
.block {
  border-width: 1px;
  border-color: $gray-light;
  border-style: solid;
  padding-top: 30px;
  padding-bottom: 30px;

  background-color: $gray;
}
.block_left {
  border-width: 1px 0 1px 1px;
  border-color: $gray-light;
  border-style: solid;
  padding-top: 30px;
  padding-bottom: 30px;

  background-color: $gray;
}
.block__header {
  padding-bottom: 30px;
}
.title {
  font-size: 24px;
  color: $white;
  font-weight: 400;
  margin: 0;
}
.subtitle {
  color: $white;
  font-size: 16px;
  font-weight: 300;
}

/* bottom-block */
.bottom-block {
  border-width: 0 1px 1px 1px;

  border-color: $gray-light;
  border-style: solid;
  padding-top: 30px;
  padding-bottom: 30px;

  background-color: $gray;
}
.help-text {
  color: $white;
  font-size: 20px;
  font-weight: 400;
  padding-bottom: 15px;
}

.services-blocks {
  padding-bottom: 100px;
  position: relative;
}
.serv {
  position: relative;
}
#serv-rictangle {
  height: 140vh;
  position: absolute;
  left: 100%;
  top: 70%;
  transform: translate(-50%, -50%);
}

.circle-logo-canvas {
  position: relative;
  
  border: 1px solid;
  border-color: rgba($gray-light, 0.4);
  background-color: $gray-dark;
  
  height: 60px;
  width: 60px;

  margin-left: 15px;

  overflow: hidden;
}
.circle-logo-canvas_img {
  object-fit: contain;
  height: 100%;
  width: 100%;

  padding: 10px;
}
.card_title {
  font-size: 22px;
  font-weight: 500;
}
.card_text {
  font-size: 16px;
  font-weight: 400;
  color: $gray__ultra_light;
}
</style>
